<template>
  <div class="readme-article">
    <h2 id="客户资料卡">2.客户资料卡</h2>
    <h3 id="基本信息">2.1基本信息</h3>
    <p>设置客户基本字段信息，可修改选择需要显示或隐藏的基本信息。</p>
    <p>
      <img src="@/assets/img/scrm/4-4.png" />
    </p>
    <p>
      <img src="@/assets/img/scrm/4-5.png" />
    </p>
    <h3 id="自定义字段">2.2自定义字段</h3>
    <p>
      自定义字段，根据情况管理员可自定义字段，自定义字段支持文本、单选、多选、时间四种类型。
    </p>
    <p>
      <img src="@/assets/img/scrm/4-6.png" />
    </p>
    <p>
      <img src="@/assets/img/scrm/4-7.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm4-2",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>